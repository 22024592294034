import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import Login from "./modules/Auth/pages/Login";
import Logout from "./modules/Auth/pages/Logout";

import Settings from "./modules/Settings/pages/Settings";

import Dashboard from "./modules/Dashboard/pages/Dashboard";

import ClinicPage from "./modules/Clinic/pages/ClinicPage";
import ClinicEdit from "./modules/Clinic/pages/ClinicEdit";
import ClinicForm from "./modules/Clinic/pages/ClinicCreate";



import RegionClinicPage from "./modules/ClinicRegion/pages/ClinicPage";
import RegionClinicEdit from "./modules/ClinicRegion/pages/ClinicEdit";
import RegionClinicForm from "./modules/ClinicRegion/pages/ClinicCreate";



import DentistPage from "./modules/Dentist/pages/DentistPage";
import DentistEdit from "./modules/Dentist/pages/DentistEdit";
import DentistForm from "./modules/Dentist/pages/DentistCreate";

import Hygienist from "./modules/Hygienist/pages/Hygienist";
import HygienistEdit from "./modules/Hygienist/pages/HygienistEdit";
import HygienistForm from "./modules/Hygienist/pages/HygienistCreate";

import Assistant from "./modules/Assistant/pages/Assistant";
import AssistantEdit from "./modules/Assistant/pages/AssistantEdit";
import AssistantForm from "./modules/Assistant/pages/AssistantCreate";

import Staff from "./modules/Staff/pages/Staff";
import StaffEdit from "./modules/Staff/pages/StaffEdit";
import StaffForm from "./modules/Staff/pages/StaffCreate";

import DentalTechnician from "./modules/DentalTechnician/pages/DentalTechnician";
import DentalTechnicianEdit from "./modules/DentalTechnician/pages/DentalTechnicianEdit";
import DentalTechnicianForm from "./modules/DentalTechnician/pages/DentalTechnicianCreate";

import Lab from "./modules/Lab/pages/Lab";
import LabEdit from "./modules/Lab/pages/LabEdit";
import LabForm from "./modules/Lab/pages/LabCreate";

import VeterinaryClinics from "./modules/VeterinaryClinic/pages/VeterinaryClinics";
import VeterinaryClinicEdit from "./modules/VeterinaryClinic/pages/VeterinaryClinicEdit";
import VeterinaryClinicForm from "./modules/VeterinaryClinic/pages/VeterinaryClinicCreate";

import LabTechnicians from "./modules/LabTechnician/pages/LabTechnicians";
import LabTechnicianEdit from "./modules/LabTechnician/pages/LabTechnicianEdit";
import LabTechnicianForm from "./modules/LabTechnician/pages/LabTechnicianCreate";


// distribution
import RepairClinicPage from "./modules/Distribute/pages/RepairClinicPage"
import RepairClinicUpdatePage from "./modules/Distribute/pages/Repair_Clinic_Update"

import OnlineClinicPage from "./modules/Distribute/pages/OnlineClinicPage"
import OnlineClinicUpdatePage from "./modules/Distribute/pages/Online_Clinic_Update"
// distribution

import 'antd/dist/reset.css';




function App() {


  return (
    <Router>
      <ToastContainer 
      position="bottom-center" 
      autoClose={1000}
      />

      <Routes>
        <Route  path="/login" element={<Login />} />        
        <Route path="/logout" element={<Logout />} />

        <Route  path="/" element={<Dashboard />} />
        <Route  path="/dashboard" element={<Dashboard />} />

        <Route  path="/settings" element={<Settings />} />

        {/* Base Pages  */}
        <Route path="/clinics" element={<ClinicPage />} />
        <Route path="/dentists" element={<DentistPage />} />
        <Route path="/hygienists" element={<Hygienist />} />
        <Route path="/assistants" element={<Assistant />} />
        <Route path="/staffs" element={<Staff />} />
        <Route path="/dental_technicians" element={<DentalTechnician />} />
        <Route path="/labs" element={<Lab />} />
        <Route path="/veterinary_clinics" element={<VeterinaryClinics />} />
        <Route path="/lab_technicians" element={<LabTechnicians />} />

        


        {/* single Update Pages */}
        <Route path="/clinic/:id" element={<ClinicEdit />} />
        <Route path="/dentist/:id" element={<DentistEdit />} />
        <Route path="/hygienist/:id" element={<HygienistEdit />} />
        <Route path="/assistant/:id" element={<AssistantEdit />} />
        <Route path="/staff/:id" element={<StaffEdit />} />
        <Route path="/dentaltechnician/:id" element={<DentalTechnicianEdit />} />
        <Route path="/lab/:id" element={<LabEdit />} />
        <Route path="/labTechnician/:id" element={<LabTechnicianEdit />} />
        <Route path="/veterinaryclinic/:id" element={<VeterinaryClinicEdit />} />


        {/* Create  Pages */}
        <Route path="/createClinic" element={<ClinicForm />} />
        <Route path="/createDentist" element={<DentistForm />} />
        <Route path="/createHygienist" element={<HygienistForm />} />
        <Route path="/createAssistant" element={<AssistantForm />} />
        <Route path="/createStaff" element={<StaffForm />} />
        <Route  path="/createDentalTechnician" element={<DentalTechnicianForm />}/>
        <Route path="/createLab" element={<LabForm />} />
        <Route path="/createLabTechnician" element={<LabTechnicianForm />} />
        <Route path="/createVeterinaryClinic" element={<VeterinaryClinicForm />} />



        {/* by region  */}
        <Route path="/region-clinics" element={<RegionClinicPage />} />
        <Route path="/region-clinic/:id" element={<RegionClinicEdit />} />
        <Route path="/region-createClinic" element={<RegionClinicForm />} />
        {/* distribute  */}

        
        <Route path="/repair_clinics" element={<RepairClinicPage />} />
        {/* <Route path="/repair_clinics_update" element={<RepairClinicUpdatePage />} /> */}

        <Route path="/online_clinics" element={<OnlineClinicPage />} />
        {/* <Route path="/online_clinics_update" element={<OnlineClinicUpdatePage />} /> */}

      </Routes>
    </Router>
  );
}

export default App;






