import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate} from 'react-router-dom'
import Navbar from "../../../global/components/common/NavBar"
import Footer from "../../../global/components/common/Footer"

import { Link } from 'react-router-dom'
import DataTable from "react-data-table-component"
import PaginationTableMain from '../../../global/utils/PaginationTableMain'
import { pageSizeOptions } from '../../../global/utils/PageConfig';

import PhoneNumberDisplay from '../../../global/utils/PhoneNumberDisplay'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import verifiedLogo from '../../../assets/images/verified.png'


import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';



const ClinicAll = () => {
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(30)
  const [searchQuery, setSearchQuery] = useState('')

  const [selectedIds, setSelectedIds] = useState([]); 

  const navigate = useNavigate()

  const baseURL = process.env.REACT_APP_API_URL
  
  const token = localStorage.getItem("token")
  const is_superuser = localStorage.getItem("is_superuser")

  const columns = [

    {
      name: "",
      cell: (row) => (
        <input
          type="checkbox"
          checked={selectedIds.includes(row.customer_number)}
          onChange={() => handleSelect(row.customer_number)}
        />
      ),
      width: '3%',
      ignoreRowClick: true, 
    },

    {
      name: "ID",
      selector: (row) => row.customer_number,
      cell: (row) => (
        <a
          className="cp"
          href={`/clinic/${row.id}/`}
          id={row.id}
          target="_blank"
        >
          {row.customer_number}
        </a>
      ),
      sortable: true,
      width: '7%',
    },
    {
      name: "Name",
      selector: (row) => row.name,
      cell: (row) => (
        <a
          className="cp text_ellipsis"
          href={`/clinic/${row.id}/`}
          id={row.id}
          target="_blank"
        >
          {row.name}
        </a>
      ),
      sortable: true,
      width: '15%',
      textAlign: "right",
    },
    {
      name: "City",
      selector: (row) => row.city,
      cell: (row) => (
        <a
          className="cp"
          href={`/clinic/${row.id}/`}
          id={row.id}
          target="_blank"
        >
          {row.city}
        </a>
      ),
      sortable: true,
      width: '10%',
      textAlign: "right",
    },
    {
      name: "Province",
      selector: (row) => row.province_state,
      cell: (row) => (
        <a
          className="cp"
          href={`/clinic/${row.id}/`}
          id={row.id}
          target="_blank"
        >
          {row.province_state}
        </a>
      ),
      sortable: true,
      width: '10%',
      textAlign: "right",
    },
    {
      name: "Country",
      selector: (row) => row.country,
      cell: (row) => (
        <a
          className="cp"
          href={`/clinic/${row.id}/`}
          id={row.id}
          target="_blank"
        >
          {row.country}
        </a>
      ),
      sortable: true,
      width: '10%',
      textAlign: "right",
    },
    {
      name: "Email",
      selector: (row) => row.email_1,
      cell: (row) => (
        <span className='tlow text_ellipsis'>
          {row.email_1}
        </span>
      ),
      width: '15%',
    },
    {
      name: "Phone",
      selector: (row) => row.phone_1,
      cell: (row) => (
          <PhoneNumberDisplay value={row.phone_1} />
      ),
      sortable: true,
      width: '12%',
    },    
    {
      name: "Website",
      selector: (row) => row.website,
      cell: (row) => (
        <span className="cp text_ellipsis tlow" >
          {row.website}
        </span>
      ),
      sortable: true,
      width: '12%',
    },    
    {
      name: "Act",
      selector: (row) => row.is_active,
      cell: (row) => (
        <div className="fw fl tc">
          {row.is_active ? 
            <CheckCircleOutlined style={{ color: 'green' }} /> : 
            <CloseCircleOutlined style={{ color: 'red' }} />
          }
        </div>
      ),
      sortable: true,
      width: '3%',
    },    
    {
      name: "Ver",
      selector: (row) => row.is_verified,
      cell: (row) => (
        <>
          { row.is_verified &&
              <img src={verifiedLogo} alt="Verified" className='w15 h_auto' /> 
          }
        </>
      ),
      sortable: true,
      width: '3%',
    },
  ]
  


  const fetchData = async (page, pageSize, searchQuery) => {

    setLoading(true);
    try {
      const res = await axios.get(`${baseURL}/clinics/api/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          page,
          page_size: pageSize,
          search: searchQuery, 
        },
      })

      const data = res.data.data.results
      const count = res.data.data.count

      setData(data)
      setTotal(count)

    } catch (error) {
      console.error('Error fetching data', error)
    } finally {
      setLoading(false)
    }
  }



  useEffect(() => {
      document.title = "Dentals | Dental Clinics"

      if (!(token && is_superuser === "true")) {
        navigate("/login");
      }

    fetchData(page, pageSize, searchQuery)
  }, [page, pageSize, searchQuery])



  const handlePageChange = (page, itemsPerPage) => {
    setPage(page)
    setPageSize(itemsPerPage)
    console.log(`Current Page: ${page}, Items per Page: ${itemsPerPage}`);
  }
  
  const cnameChange = (e) => {
    setSearchQuery(e.target.value);
  }
  


  const handleSelect = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    })

    console.log(selectedIds)
  }



  const fetchDataAddClinicFrom1db = async (clinicNumber) => {
    const idString = selectedIds.join('___');
  
    setLoadingUpdate(true);
    try {
      const res = await axios.get(`${baseURL}/distributes/api/repair_clinics_add_from_main/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          idString: idString,
          clinic_number: clinicNumber,
          db_name: 'clinic',
        },
      })
  
      setSelectedIds([])
  
      const status = res.data.status
  
      window.location.reload()
  
    } catch (error) {
      console.error('Error fetching data', error)
    } finally {
      setLoadingUpdate(false)
    }
  
  }
  
  const addClinicFromDb = (clinicNumber) => {
    console.log('clicked')
    fetchDataAddClinicFrom1db(clinicNumber)
  }
  

 // dropdown

 const items = [
  {
    label: (
      <a onClick={() => addClinicFromDb(1)}>
        Repair.Dental
      </a>
    ),
    key: '1',
  },
  {
    label: (
      <a onClick={() => addClinicFromDb(2)}>
        Online.Dental
      </a>
    ),
    key: '2',
  },  
  {
    label: (
      <a onClick={() => addClinicFromDb(3)}>
        All
      </a>
    ),
    key: '3',
  },
]



  return (
    <>
      <Navbar />
      <div className="w98p ml1p fl mt20 h75vh_m">
        <div className='fw fl box-shadow-2 mb5 bg-w p10'>


          <div className="fw fl rel dataTable_1db_container datatable_one_container">

            <div className="abs right0 top0 zi10">
              <Link to='/createClinic' className="add_plus">
                <span className="add_plus_symbol">+</span>
              </Link>
            </div>

            <div className="abs right100 top10 zi10">
                { selectedIds && selectedIds.length > 0 && (

                loadingUpdate ? (
                  <span>Updating ...</span>
                ) : (

                  <>
                  <Dropdown
                    menu={{
                      items,
                    }}
                  >
                      <Space className='cp'>
                        ADD/UPDATE
                        <DownOutlined />
                      </Space>
                  </Dropdown>

                  {/* <span onClick={addClinicFrom1db} className="updateButton1db">Add</span> */}

                </>


                )

                )}
            </div>

            <input
              type="text"
              placeholder="Search here"
              className="w300 abs left0 zi10 fl  form-control bd"
              onChange={cnameChange}
            />

                            
          { loading ? ( 

          <div className="fw fl tc mt30 mb30">
          <p>Please wait...</p>
          </div> 

          ) : (



            data && data.length > 0 ? (  

              <DataTable
                className="DatatableOne Datatable_edited"
                columns={columns}
                data={data}
                highlightOnHover
                subHeader

              />

            ):(
              <div className="fw fl tc mt30 mb30">
                <p>No data available.</p>
              </div> 
            )
            
          )}



          </div>


          { total > 0 &&           

            <div className="fw fl mt30 mb100 pl15 pr15">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="fw fl">
                      
                      <PaginationTableMain totalItems={total} pageSize={pageSize} sizeOptions={pageSizeOptions} onPageChange={handlePageChange} />

                    </div>
                  </div>
                </div>
              </div>
            </div>

          }

        </div>
      </div>
      <Footer />
    </>
  );
};

export default ClinicAll;
