
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select } from 'antd';

const baseURL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");

const { Option } = Select;

const MultiSelectAutoCompleteBranch = ({ name, model, setValue, value_main }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchSelectedOptions = async (selectedIds) => {
    if (selectedIds.length === 0) return;

    setLoading(true);
    try {
      const response = await axios.get(`${baseURL}/${model}/api/search_branch/`, {
        params: { ids: selectedIds.join(",") },  
        headers: {
          Authorization: `Token ${token}`,
        },
      });


      const data = response.data;
      const mappedOptions = data.map(item => ({
        id: item.id,
        label: `${item.customer_number} - ${item.name} - ${item.phone_1} - ${item.email_1}`,
      }));
      setOptions(mappedOptions);
    } catch (error) {
      console.error('Error fetching selected options:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (inputValue) => {
    if (inputValue) {
      setLoading(true);
      try {
        const response = await axios.get(`${baseURL}/${model}/api/search/`, {
          params: { q: inputValue },
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        const data = response.data;
        setOptions(data.map(item => ({
          id: item.id,
          label: `${item.customer_number} - ${item.name} - ${item.phone_1} - ${item.email_1}`,
        })).slice(0, 10));
      } catch (error) {
        console.error('Error fetching search results:', error);
        setOptions([{ id: 1, label: 'No data found!' }]);
      } finally {
        setLoading(false);
      }
    } else {
      setOptions([]);
    }
  };

  const handleChange = (selectedIds) => {
    setValue(selectedIds);  
  };


  useEffect(() => {
    if (value_main && value_main.length > 0) {
      fetchSelectedOptions(value_main);  
    } else {
      setOptions([]);  
    }
  }, [value_main]);  

  return (
    <Select
      mode="multiple"
      style={{ width: '100%' }}
      placeholder={`Select ${name}`}
      onSearch={handleSearch}
      onChange={handleChange}
      showSearch
      filterOption={false}  
      notFoundContent={loading ? 'Loading...' : null}  
      value={value_main}  
    >
      {options.map(o => (
        <Option key={o.id} value={o.id}>
          {o.label}  
        </Option>
      ))}
    </Select>
  );
};

export default MultiSelectAutoCompleteBranch;



















// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Select } from 'antd';

// const baseURL = process.env.REACT_APP_API_URL;
// const token = localStorage.getItem("token");
// const { Option } = Select;

// const MultiSelectAutoCompleteBranch = ({ name, model, setValue, value_main }) => {
//   const [options, setOptions] = useState([]);
//   const [loading, setLoading] = useState(false);

//   const fetchInitialOptions = async (selectedIds) => {
//     if (selectedIds.length === 0) return; 

//     try {
//       const response = await axios.get(`${baseURL}/${model}/api/search_branch/`, {
//         params: { ids: selectedIds.join(",") }, 
//         headers: {
//           Authorization: `Token ${token}`,
//         },
//       });

//       const data = response.data;
//       const initialOptions = data.map(item => ({
//         id: item.id,
//         label: `${item.customer_number} - ${item.name}`,
//       }));

//       setOptions(initialOptions);
//     } catch (error) {
//       console.error('Error fetching initial options:', error);
//     }
//   };

//   // Handle search input
//   const handleSearch = async (inputValue) => {
//     if (inputValue) {
//       setLoading(true);
//       try {
//         const response = await axios.get(`${baseURL}/${model}/api/search/`, {
//           params: { q: inputValue },
//           headers: {
//             Authorization: `Token ${token}`,
//           },
//         });

//         const data = response.data;
//         const searchedOptions = data.map((item) => ({
//           id: item.id,
//           label: `${item.customer_number} - ${item.name}`,
//         }));

//         setOptions(searchedOptions);
//       } catch (error) {
//         console.error('Error fetching search results:', error);
//       } finally {
//         setLoading(false);
//       }
//     } else {
//       setOptions([]);
//     }
//   };

//   const handleChange = (selectedIds) => {
//     if (selectedIds.length === 0) {
//       setValue([]); 
//       return;
//     }
//     const updatedSelection = selectedIds.map(id => 
//       options.find(option => option.id === id)
//     );

//     setValue(updatedSelection);
//   };

//   useEffect(() => {
//     if (value_main && value_main.length > 0) {
//       const selectedIds = value_main.map(item => item.id);  
//       fetchInitialOptions(selectedIds);
//     }
//   }, [value_main]);  

//   return (
//     <Select
//       mode="multiple"
//       style={{ width: '100%' }}
//       placeholder={`Select ${name}`}
//       onSearch={handleSearch}
//       onChange={handleChange}
//       showSearch
//       filterOption={false}  // Disable default filtering since we're handling it ourselves
//       notFoundContent={loading ? 'Loading...' : null}  // Show a loading message while fetching
//       value={value_main.map(item => item.id)}  // Use only IDs in the value prop
//     >
//       {options.map((option) => (
//         <Option key={option.id} value={option.id}>
//           {option.label}  {/* Display the label for each option */}
//         </Option>
//       ))}
//     </Select>
//   );
// };

// export default MultiSelectAutoCompleteBranch;




















